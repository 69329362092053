<template>
    <div class="row mb-2" style="min-height: 140px">
        <div class="col-12 d-flex align-items-center justify-content-between position-relative">
            <div class="d-flex justify-content-center align-items-center" style="line-height: 23px">
                <div class="pr-2 pb-1">
                    <Icon :icon-name="icon"
                          width="23"
                          height="23"
                          :fill="color"/>
                </div>
                <div v-if="lengthOfName > 20"
                     class="list-group-item-title"
                     data-toggle="tooltip"
                     :title="name">
                    {{ stringLimit(name, 20) }}
                </div>
                <div v-else class="list-group-item-title">
                    {{ name }}
                </div>
            </div>
            <div v-if="status !== null" class="d-flex align-items-center list-group-item-value">
                <div>{{ !status ? $t('Закрыто') : $t('Открыто') }}</div>
                <div class="ml-2">
                    <i v-if="!status" class="fas fa-lg fa-lock text-success"></i>
                    <i v-else class="fas fa-lg fa-unlock text-danger"></i>
                </div>
            </div>
            <div v-else class="d-flex align-items-center list-group-item-value">
                <div>N/A</div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center w-100">
            <ValveCard_2Button
                :deviceObject="deviceObject"
            />
        </div>

    </div>
</template>

<script>

import Icon from "../../Icon";
import DefaultCard_3ValuesList from "../default/DefaultCard_3ValuesList";
import ValveCard_2Button from "./ValveCard_2Button";

export default {
    name: "ValveCard_3ValuesList",
    extends: DefaultCard_3ValuesList,
    inject: ["getInjectedStatus"],
    components: {
        ValveCard_2Button,
        Icon,
    },
    computed: {
        status() {
            return this.getInjectedStatus();
        },
        name() {
            return this.currentMetric?.getName()
        },
        lengthOfName() {
            return this.currentMetric?.getName()?.length
        },
        icon() {
            return this.currentMetric?.getIcon()
        },
        color() {
            return this.currentMetric?.getColor()
        }
    },
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;
        border-radius: .5rem;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
            color: #495057;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: #f1f1f1;
        }
    }
}

</style>
