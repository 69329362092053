import StorageAdapter from "./StorageAdapter";

export default class TemporaryOption {

    constructor(diskName = "temporary") {
        this.storage = new StorageAdapter(diskName);
    }

    _get(key) {
        const data = this.storage.get(key, null);

        if (data === null || Date.now() > data.expired) {
            this.storage.remove(key);
            return null;
        }

        return data;
    }

    get(key, def = null) {
        const data = this._get(key);
        return data === null ? def : data.value;
    }

    set(key, value, ttl) {
        this.storage.set(key, {
            value: value,
            expired: Date.now() + ttl
        });
    }

    remove(key) {
        this.storage.remove(key);
    }

    isExpired(key) {
        const data = this._get(key);
        return data === null;
    }

    getTtl(key) {
        const data = this._get(key);
        return data === null
            ? -1
            : data.expired - Date.now();
    }
}
