<script>

    import ValveCard_1Button from "./ValveCard_1Button"

    export default {
        name: "ValveCard_2Button",
        extends: ValveCard_1Button,
    }
</script>

<style scoped lang="less">
    .btn-valve {
        width: 200px;
        height: 45px;
        background-color: #610341;
        text-align: center;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin: 15px 0!important;
        transition: all .3s ease-in-out;

        &:hover {
            background-color: #350036;
        }
    }
</style>
