<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import ValveCard_1Table from "./ValveCard_1Table";
import ValveCard_3ValuesList from "./ValveCard_3ValuesList";

export default {
    name: "ValveCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: ValveCard_3ValuesList,
        ChartComponent: ValveCard_1Table,
        Icon
    },
}
</script>
