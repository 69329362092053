<template>
    <div :style="{backgroundColor:  chartColor || '#e3e3e3' }" class="gerkon-statistic">
        <div v-for="(item, index) in slicedTimeseries" :key="index" class="mb-2 pl-1 row">
            <div v-if="item.value !== null" class="text-nowrap pl-1" style="width: 40%">
                <div class="gerkon-statistic-icon d-inline-flex justify-content-center align-items-center">
                    <i v-if="item.value === 0" class="fas fa-lock text-success"></i>
                    <i v-else class="fas fa-unlock text-danger"></i>
                </div>
                {{ item.value === 0 ? $t('Закрыто') : $t('Открыто') }}
            </div>
            <div v-else class="text-nowrap pl-1" style="width: 40%">
                <div class="gerkon-statistic-icon d-inline-flex justify-content-center align-items-center">
                    <i class="fas fa-lock text-muted"></i>
                </div>
                N/A
            </div>
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="fas fa-calendar-alt"></i>
                <strong> {{ item.date }}</strong>
            </div>
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="far fa-clock"></i>
                <strong> {{ item.time }}</strong>
            </div>
        </div>
    </div>
</template>

<script>

import config from "../../../config";
import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import moment from "moment-mini";

export default {
    name: "ValveCard_1Table",
    extends: DefaultCard_1AbstractChart,
    computed: {
        slicedTimeseries() {
            const res = this.timeseries?.find(item => item.getSlug() === 'res')?.getData().slice(0, 6);

            return res?.map(item => {
                let value
                if (item.getValue() === 0 ) {
                    value = 0
                } else if (item.getValue() === 1) {
                    value = 1
                } else {
                    value = null
                }

                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: value,
                };
            });
        },
    },
}
</script>

<style scoped lang="less">

.gerkon-statistic {
    border-radius: 10px;
    height: 190px;
    padding: 1rem 1.5rem;
    font-size: 13px;
    color: #fff;

    &-item {
        margin: 5px 0;
    }

    &-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: white;
    }

    &-time {
        min-width: 80px;
        font-weight: 600;
        line-height: 14px;
    }
}
</style>
