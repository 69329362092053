<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import ValveCard_1Table from "./ValveCard_1Table";
import ValveCard_2LatestValue from "./ValveCard_2LatestValue";
import ValveCard_2Button from "./ValveCard_2Button";

export default {
    name: "ValveCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        LatestValueComponent: ValveCard_2LatestValue,
        MetricsButtonsComponent: ValveCard_2Button,
        ChartComponent: ValveCard_1Table,
        Icon
    },
    computed: {
        metricImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();

            try {
                const iconFile = require(`../../../assets/metrics/${deviceSlug}.png`);

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
    }
}
</script>
