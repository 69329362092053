<template>
    <div ref="latestValue" class="latest-value">
        <div v-if="status !== null">
            <div class="d-flex justify-content-center align-content-center text-center">
                <div>
                    <div class="status-icon mx-auto">
                        <i v-if="!status" style="font-size: 1.4rem" class="fas fa-lock text-success"></i>
                        <i v-else style="font-size: 1.4rem" class="fas fa-unlock text-danger"></i>
                    </div>
                    <div class="d-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                        {{ !status ? $t('Закрыто') : $t('Открыто') }}
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="latest-value__value">N/A</div>
        </div>
    </div>
</template>

<script>

import ValveCard_1LatestValue from "./ValveCard_1LatestValue";
import {mapState} from "vuex";

export default {
    name: "ValveCard_2LatestValue",
    extends: ValveCard_1LatestValue,
}
</script>

<style lang="less" scoped>
.latest-value {
    width: 100%;
    padding: 10px 0 0;


    .status-icon {
        display: inline-block;
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }
}

</style>
