<script>

import Icon from "../../Icon";
import ValveCard_1LatestValue from "./ValveCard_1LatestValue";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import ValveCard_1Table from "../valve/ValveCard_1Table";
import ValveCard_1Button from "../valve/ValveCard_1Button";

export default {
    name: "ValveCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        LatestValueComponent: ValveCard_1LatestValue,
        MetricsButtonsComponent: ValveCard_1Button,
        ChartComponent: ValveCard_1Table,
        Icon
    },
}
</script>
