<template>
    <button v-confirm="changeStatus" class="btn btn-valve my-2 mr-2" :disabled="processing">
        <div v-if="processing" class="w-100">
            <div class="spinner-border spinner-border-sm text-white" role="status">
                <span class="sr-only"></span>
            </div>
            {{ $t('Выполнение...') }} {{ counter }}
        </div>
        <div v-else>
            {{ $t('Изменить положение') }}
        </div>
    </button>
</template>

<script>

import DownlinkService from "../../../services/DownlinkService";
import DeviceObject from "../../../objects/DeviceObject";

import TemporaryOption from "@/classess/TemporaryOption";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();

export default {
    name: "ValveCard_1Button",
    inject: ["getInjectedStatus"],
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    data() {
        return {
            processing: false,
            loop: null,
            tempOptionName: "valve_control_processing",
            delay: 60000,
            counter: null
        }
    },
    methods: {
        async changeStatus() {
            TempOption.set(this.tempOptionName, true, this.delay)
            this.counter = 60
            this.processing = true;
            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), !this.status ? "open" : "close");
            if (result) {
                this.$toast.info(this.$t('Команда на изменение отправлена'));
            } else {
                TempOption.remove(this.tempOptionName);
                this.$toast.error(this.$t('Ошибка!'));
                this.processing = false;
                return;
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter
            this.loop = setInterval(() => {
                this.counter--
                if ( this.counter <= 0 ) {
                    TempOption.remove(this.tempOptionName);
                    clearInterval(this.loop);
                    this.processing = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    computed: {
        status() {
            return this.getInjectedStatus();
        }
    },
    mounted() {
        const isProcessing = TempOption.get(this.tempOptionName, false);
        const optionTtl = TempOption.getTtl(this.tempOptionName);

        if (isProcessing) {
            this.processing = true;
            this.done(Math.floor(optionTtl / 1000));
        }
    }
}
</script>

<style scoped lang="less">
.btn-valve {
    width: 200px;
    height: 37px;
    background-color: #610341;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 14px;
    transition: all .3s ease-in-out;

    &:hover {
        background-color: #350036;
    }
}
</style>
