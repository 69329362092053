<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg" :style="deviceImage">
            <div v-if="status !== null">
                <div class="d-flex justify-content-center align-content-center text-center">
                    <div>
                        <div class="status-icon mx-auto">
                            <i v-if="!status" style="font-size: 1.4rem" class="fas fa-lock text-success"></i>
                            <i v-else style="font-size: 1.4rem" class="fas fa-unlock text-danger"></i>
                        </div>
                        <div class="d-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                            {{ !status ? $t('Закрыто') : $t('Открыто') }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1LatestValue from "../default/DefaultCard_1LatestValue";

export default {
    name: "ValveCard_1LatestValue",
    extends: DefaultCard_1LatestValue,
    inject: ["getInjectedStatus"],
    computed: {
        deviceImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();
            try {
                const iconFile = require(`../../../assets/metrics/${deviceSlug}.png`);

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
        status() {
            return this.getInjectedStatus();
        }
    }
}
</script>

<style lang="less" scoped>
@import "~animate.css";

.latest-value {
    width: 100%;
    padding: 0;

    &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 170px;
        height: 170px;
        background-color: #ccc;
        border-radius: 100%;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    .status-icon {
        background-color: white;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }
}
</style>
