<!--<template>-->
<!--    <component :is="currentCardComponent"-->
<!--               :deviceObject="deviceObject"-->
<!--               :timeseries="timeseries"-->
<!--               :isCurrentUserDevice="isCurrentUserDevice"-->
<!--               @onLoad="() => this.loading = false"-->
<!--               @deviceDoUpdate="$emit('deviceDoUpdate', $event)"-->
<!--               @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', $event)"-->
<!--               @deviceDoRemove="$emit('deviceDoRemove', $event)"/>-->
<!--</template>-->

<script>
import ValveCard_1 from "./ValveCard_1";
import ValveCard_2 from "./ValveCard_2";
import ValveCard_3 from "./ValveCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractValveCard",
    extends: AbstractDefaultCard,
    components: {
        ValveCard_1,
        ValveCard_2,
        ValveCard_3,
    },
    provide() {
        return {
            getInjectedStatus: () => this.status
        }
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return ValveCard_3;
                case 2:
                    return ValveCard_2;
                default:
                    return ValveCard_1
            }
        },
        status() {
            const res = this.timeseries
                ?.find(item => item.getSlug() === 'res')
                ?.getLatestData()
                ?.getValue();

            if (res === 1) {
                return true;
            }

            if (res === 0) {
                return false;
            }

            return null;
        },
    }
}
</script>
